.container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 80px;
    margin-bottom: 80px;
    background: url(../../img/arte1cc.png) no-repeat ;
    height: 600px;

    div > h1, h2, p{
       color: var(--color-brown); 
       
    }    

    h1, h2{
       text-align: center;
    }
    p{
        padding: 5px 10px 0 140px;
    }

    div{
        p{
            max-width: 1200px;
        }
        margin: 0 15px;
    }
}
    .quemsomos{
        padding-left: 126px;
    }
@media (max-width:800px) {

    .container{
        background: url(../../img/arte1c.png) no-repeat;
        flex-direction: row;
        margin-top: 100px;
        height: 600px;

        img{
            //height: 400px;
            height: 0px;

        }

        p{
            //color: var(--color-black);
            padding: 5px 10px 0 2px;
    
        }
    }
    .quemsomos{
        padding-left: 0px;
    }
}


@media (min-width:800px) {
    .container{
        img{
        display: none;
        }
    }
    
}