.animated_hr {

    margin: 0px auto;
    width: 80%;
    border: none;
    border-radius: 10px;
    height: 3px;
    opacity: 1;
    background-color: var(--color-brown);
    animation: animated-hr-animation 3s ease-in-out  2;


  }
  
  @keyframes animated-hr-animation {
    0% {
      transform: scaleX(0);
    }
    100% {
      transform: scaleX(1);
    }
  }