.button_modal{
    background-color: rgba(0, 0, 0, 0) !important;
    border: none !important;
    text-align: start ;
}

.modal_name{
    color: var(--color-brown);
    font-size: 1.6rem;
}
.box_media{
    color: white;
    background-color: var(--color-brown);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding:0 20px;
    margin:20px auto ;
    img{
        width: 60px;
        margin:0 5px;
    }
    p{
        //padding-top: 5px;
        font-size: 1.2rem;
        margin:0;
    }
    button{
        background-color: var(--color-brown);
        border: none;
        
    }
}

.button_fechar{
    background-color: var(--color-brown) !important;
    border: none;
    
}

@media (max-width:990px) {
    .button_modal{
        padding: 8px 0 ;
    }
}