.container_footer2{
    display: flex;
    justify-content: center;
    background-color: var(--color-pink);
    min-width: 100%;
    
}

.container_footer2{
    display: flex;
    align-items: center;
    height: 450px;
    padding: 20px;
    //margin-top: 200px;
    width: 1000px;
    .logop{
        width: 250px;
        padding-right: 20px;
    }
}
.container_1{
    margin-left: 40px;
}

.container_2{
    margin-top: 20px;
}
.container_redes{
    display: flex;
    button{
        border: none;
        background-color: transparent;
    }

    img{
        width: 25px;
        margin:0 20px;
        transition: 0.5s;
    }
    img:hover{
        transform: scale(1.1);
    }
}


@media (max-width:800px) {
    .container_footer2{
        display: flex;
        flex-direction: column ;
        justify-content: center;
        margin-top: 80px;
        height: 800px;
        max-width: 100%;
        img{
            margin-bottom: 50px;

        }

    }

    .container_1{
        margin-top: 50px;
        margin-left: 0;
    }

    .container_2{
        margin-top: 51px;
    }

    .container_redes{
    
        img{
            width: 25px;
            margin:0 20px 50px 10px;
        }
    }
}
@media (max-width:400px) {
    .container_footer2{
        margin-top: 140px;
    }
}