.container{
    margin-top: 80px;

    h1{
        text-align: center;
        color: var(--color-brown);
    }

    margin-bottom: 80px;

}

.buttonContact{
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
    transition: 0.8s;
    a{
        border: none;
        background-color: var(--color-brown);
        color: white;
        padding: 10px 25px;
        font-size: 1.4rem;
        border-radius: 4px;       
        transition: 0.5s;
        text-decoration: none;
    }
}
.buttonContact:hover{
    transform: scale(1.05);
    
    a{
        box-shadow: 1px 3px 10px 0px #000000a1;
    }
}
.container_card1{
    margin: 20px;
    display: flex;
    justify-content: center;

}




.card{
    max-width: 400px;
    margin: 20px;
    border-radius: 8px;
    

    background-color: white;
    border-radius: 5px;

    p{
        text-align: center;
        color: var(--color-brown);
        padding: 5px;
    }
    h2{
        text-align: center;
        color: var(--color-brown);
    }
    img{
        width: 400px;
        border-radius: 5px;
        //box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;   
        margin-bottom: 20px;
        transition: 0.5s;
    }
    img:hover{
        //transform: scale(1.05);
        transform: scale(0.98);
    }
}

@media (max-width:1400px) {
    .card{
        max-width: 300px;

        h2{
            font-size: 1.5rem;
        }

        img{
            max-width: 300px !important;
        }
    }
}
@media (max-width:1000px) {
    .container{
        margin-top: 100px;
    }
   .container_card1 div{
        gap: 20px;
        margin: 10px ;
    } 
    div.container_card1{
        overflow-x: scroll;
        justify-content: start;
    }

    .container_card div{
        gap: 20px;
        margin: 10px ;
    } 
    div.container_card{
        overflow-x: scroll;
        justify-content: start;
    }
}
   
    .container_card{
        display: none;
    }

    
@media (max-width:800px) {
    .container_card{
        margin: 20px;
        display: flex;
        justify-content: center;
        
    } 
    .buttonContact{
        margin-bottom: 70px;
        a{
            font-size: 1.2rem;
        }
    }
}

/* width */
::-webkit-scrollbar {
    width: 10px;
    height: 5px;
  }
  
  /* Track */
//   ::-webkit-scrollbar-track {
//     box-shadow: inset 0 0 1px; 
//   }
  
  /* Handle */


  ::-webkit-scrollbar-thumb {
    background: var(--color-brown); 
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: var(--color-brown); 
  }