.navbar_container{
    background-color: var(--color-brown) !important;
}

.navbar_links{
    a{
        color: white;
    }
    button:hover{
        color: rgba(255, 255, 255, 0.716);
    }
}
.logo{
    width: 183px;
    height: 40px;
}