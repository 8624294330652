*{
    color: var(--color-brown);
}

.container_diferencial{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 80px auto ;
    padding: 0  40px;
    max-width: 1200px;
    .logo{
        width: 200px;
    }

    h2,p{
        text-align: center;
    }
}


@media (max-width:800px) {
    .container_diferencial{
        margin: 20px;
        padding: 0px;
    }
}