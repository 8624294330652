
@font-face {
    font-family: 'codec';
    src: url('fonts/codec-pro/CodecPro-Regular.ttf')  format('truetype');
}

@font-face {
    font-family: 'norms';
    src: url('fonts/TT-Norms-Fonts/TTNorms-Regular.otf')  format('opentype');
}

*{
    padding: 0px;
    margin: 0px;
    outline: 0px;
    box-sizing: border-box;
}

:root{
    /* --color-brown:#BC9778;
    --color-light-brown: #E6D9C2;
    --color-pink:#F7E6D5 ;
    --color-light-grey:#F5F5EF;
    --color-white:#fff;
    --color-grey:#4D4D4D; */

    --color-brown:#65273E;
    --color-light-brown: #94495D;
    --color-pink:#BD6266 ;
    --color-light-grey:#D8938C;
    --color-white:#fff;
    --color-grey:#DFA6A1;
}


body{
    background-color:#F5F5EF !important;
}

h1, h2, h3{
    font-family: 'codec';
}

a,p{
    font-family: 'norms';
}