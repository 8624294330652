.img_container{
    display: flex;
    justify-content: center ;
    align-items: flex-end;
    margin: auto;
    height: 780px;
    background-color: #F5F5EF;
}


.img_logo{
    
    background-image: url(../../img/logo-gold.png);
    background-clip: border-box;
    background-position: bottom;
    background-size: contain;
    background-repeat: no-repeat;

    width: 80%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: flex-end;



}

@media (max-width:1400px) {

    .img_logo{
        img{
            width: 350px;
            height: 500px;
        } 
    }

    .img_container{
        height: 600px;
    }
}

@media (max-width:1000px) {

    .img_logo{
        
        img{
            width: 300px;
            height: 450px;
        } 
    }

    .img_container{
        height: 500px;
    }
}

@media (max-width:650px) {

    .img_logo{
        background-image: url(../../img/logo.png);
        background-position: center;
        background-size: cover;
        width: 100%;
        img{
            width: 300px;
            height: 450px;
        } 
    }

    .img_container{
        height: 500px;
    }
}