.container_whats{
     display: flex;
     align-items: flex-end;

    button{
        border: none;
        background-color: transparent;
        
    }
    img{
        width: 80px;
        position: fixed;
        top: 86%;
        right: 1%;
        transition: 0.5s;
    }

    img:hover{
        transform: scale(1.1);
    }
}

@media (max-width:800px) {
    .container_whats{
        img{
            width: 60px;
            position: fixed;
            top: 90%;
            right: 5%;
        }
    }
}